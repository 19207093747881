
import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MsgDialog from '../../../../components/MsgDialog';
import * as env from '../../../../env';
import { httpApiGetText } from '../../../../lib';
import LocalMsgModalSpinner from '../../../../components/LocalMsgModalSpinner';
import { Alert } from 'react-bootstrap';
import { FaRegHandPaper } from 'react-icons/fa';

const ModalCargaDocumento = (
            props: {Data: any, Show: boolean, Title: string, 
            BtnOkName: string, BtnNokName: string, formData: any
            HandlerClickOk: any, HanlerdClickNok: any, User: string}) =>{
  
  const [sHCarga, setSHCarga] = useState(false);
  const [msgAlrtDom, setMsgAlrtDom] = useState(false);  
  const [msgAlrtFile, setMsgAlrtFile] = useState(false); 
  const [fileName, setFileName] = useState("");       
  const [msg, setMsg] = useState("");  
  const [showMsgError, setShowMsgError] = useState(false);  
  const [listado, setListado] = useState(new Array<string>());
  const [clasificacion, setClasificacion] = useState(""); 
  const [fileList, setFileList] = useState<FileList | null>(null);

  const handleClose = () =>{

    setClasificacion("");
    props.HanlerdClickNok();
  }

  const onSelectChange = (evt: any) => {
    setClasificacion(evt.target.value);
  }
  
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileList(e.target.files);
    setFileName("" + e.target.files?.length);
    setMsgAlrtFile(false);
  }

  const handleUploadClick =async () => {

    if((clasificacion !== "") && (fileName !== "")){

        setSHCarga(true);

        if (!fileList) {
          return;
        }

        const data = new FormData();

        for (let idx = 0; idx < fileList.length; ++idx){
          data.append(`file-${idx}`, fileList[idx], fileList[idx].name);
        }  

        // 👇 Uploading the file using the fetch API to the server
        const recurso = `${env.REACT_APP_API_URL}/api/Archivos?Pais=CL&Anno=${props.formData.Anno}&Mes=${props.formData.Mes}&Centro=${props.formData.Centro}&Almacen=${props.formData.Almacen}&Usuario=${props.User}&Clasificacion=${clasificacion}`; 
        const response = await fetch(recurso, {
                                      method: 'POST',
                                      body: data,
                                      // 👇 Set headers manually for single file upload
                                      headers: {
                                        'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                                        'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`         
                                      },
                                    });
        const res = await response.json();
        setSHCarga(false);
        let msg = "";
        if (response.status >= 400){
          msg = res.Message;  
        }else{
          msg = "Archivo cargado con éxito.";  
        }
        setMsg(msg);    
        setShowMsgError(true); 

    }else{
      setMsgAlrtDom(()=> (clasificacion === "") );
      setMsgAlrtFile(()=> (fileName === "") );
    }
  };
  
  useEffect(()=>{
   
    const apiSelect = async () =>{
        const recurso = `${env.REACT_APP_API_URL}/api/Archivos?Pais=CL`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const arrDta = await JSON.parse(response.message); 
        setListado([...arrDta]);      
    }

    apiSelect();
    
  }, []);

return(
    <>
      <Modal show={props.Show} size="xl" >
        <Modal.Header >
          <Modal.Title>{props.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='gap-3'>
            <div>
                <div className="col-lg-6 mb-3">
                    <label htmlFor="clasificador" className="form-label">Clasificación</label>
                    <select id="clasificador" className="form-select" onChange={onSelectChange} value={clasificacion} onFocus={()=>setMsgAlrtDom(false)} >
                        <option value="" >Seleccione una clasificación...</option>
                        {
                            listado.map((dt: any, index: number) => <option value={dt.ID} key={index} >{dt.CLASIFICACION}</option>)
                        }
                    </select>
                    <Alert variant="danger" show={msgAlrtDom} className="p-1 m-0">
                            <FaRegHandPaper className='mb-1' /> Debe seleccionar una clasificación.
                    </Alert>                    
                </div>      

                <div>Adjuntar archivo</div>
             
                <div className='mb-3 mt-2'>
                  <input
                      type="file"
                      id="filename"
                      onChange={handleFileChange}
                      onFocus={()=>setMsgAlrtFile(false)}
                      className="form-control"
                      accept="*.*"
                  />
                    <Alert variant="danger" show={msgAlrtFile} className="p-1 m-0">
                            <FaRegHandPaper className='mb-1' /> Debe seleccionar un archivo.
                    </Alert>                     
                </div>  
            </div>                     
        </Modal.Body>
        <Modal.Footer>
            <div className='d-flex flex-row justify-content-end gap-4'>
                <Button onClick={handleUploadClick} className="btn btn-success">
                  {props.BtnOkName}
                </Button>          
                <Button onClick={()=>handleClose()} className="btn btn-danger">
                  {props.BtnNokName}
                </Button>                  
            </div>          
        </Modal.Footer>
      </Modal>
      <MsgDialog
        Show={showMsgError}
        Title='Carga de Documento'
        BtnOkName='Cerrar'
        HandlerClickOk={()=>setShowMsgError(false)}
        Message={msg}
        Icon='x'
        BtnNokName=''
        HanlerdClickNok={null}
      />
      <LocalMsgModalSpinner 
          Show={sHCarga}
          text="Un momento porfavor, procesando..."
          color="#FF7588"
      />         
    </>
)

}

export default ModalCargaDocumento;
